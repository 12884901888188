// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {"I2226:26693;2223:2291": {hover: true}};

const cycleOrder = ["I2226:26693;2223:2291"];

const variantClassNames = {"I2226:26693;2223:2291": "framer-v-1lrb5o6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; icon?: string; link?: string; newTab?: boolean }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "I2226:26693;2223:2291", icon: VvPKjGUFe = "TwitterLogo", link: s9YwvQf_1, newTab: fvuSJl599 = false, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I2226:26693;2223:2291", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-tyjmp", classNames)} style={{display: "contents"}}>
<Link href={s9YwvQf_1} openInNewTab={fvuSJl599}><motion.a {...restProps} className={`${cx("framer-1lrb5o6", className)} framer-1938o7g`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I2226:26693;2223:2291"} ref={ref} style={{"--border-bottom-width": "0px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} transition={transition} variants={{"I2226:26693;2223:2291-hover": {"--border-bottom-width": "1px", "--border-color": "var(--token-9ed7888c-aeb5-48dd-aee6-b885d99628b4, rgb(64, 52, 72)) /* {\"name\":\"border/default hover\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-b4804a4b-1cf7-4023-83f4-858a0ef62630, rgba(44, 33, 51, 0.5))"}}} {...addPropertyOverrides({"I2226:26693;2223:2291-hover": {"data-border": true, "data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ch755a-container"} layoutDependency={layoutDependency} layoutId={"KXTMXPw9R-container"} transition={transition}><Phosphor color={"var(--token-efbc6c56-80fe-4475-9b37-9684d6e92632, rgb(239, 237, 253)) /* {\"name\":\"icon/default\"} */"} height={"100%"} iconSearch={"House"} iconSelection={VvPKjGUFe} id={"KXTMXPw9R"} layoutId={"KXTMXPw9R"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-tyjmp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tyjmp .framer-1938o7g { display: block; }", ".framer-tyjmp .framer-1lrb5o6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: flex-start; overflow: visible; padding: 12px 12px 12px 12px; position: relative; text-decoration: none; width: min-content; }", ".framer-tyjmp .framer-1ch755a-container { flex: none; height: 18px; position: relative; width: 18px; }", ".framer-tyjmp .framer-v-1lrb5o6 .framer-1lrb5o6 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tyjmp .framer-1lrb5o6 { gap: 0px; } .framer-tyjmp .framer-1lrb5o6 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-tyjmp .framer-1lrb5o6 > :first-child { margin-left: 0px; } .framer-tyjmp .framer-1lrb5o6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"NsP7NPSwL":{"layout":["auto","auto"]}}}
 * @framerVariables {"VvPKjGUFe":"icon","s9YwvQf_1":"link","fvuSJl599":"newTab"}
 */
const FramerMBybUpqG5: React.ComponentType<Props> = withCSS(Component, css, "framer-tyjmp") as typeof Component;
export default FramerMBybUpqG5;

FramerMBybUpqG5.displayName = "Floating Nav Item";

FramerMBybUpqG5.defaultProps = {height: 42, width: 42};

addPropertyControls(FramerMBybUpqG5, {VvPKjGUFe: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "TwitterLogo", hidden: undefined, title: "Icon"}, s9YwvQf_1: {title: "Link", type: ControlType.Link}, fvuSJl599: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerMBybUpqG5, [...PhosphorFonts])